import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { Rating, Ratings } from '../../../../types/enums/Rating';
import { clientsCompaniesActions } from '../../../../actions/clients-companies.actions';
import { UpdateSettlementAgentAgreementStatus } from '../../../../types/bid-as-dealer/UpdateSettlementAgentAgreementStatus';
import { SettlementAgreementStatus } from '../../../../types/bid-as-dealer/SettlementAgreementStatus';
import { yup } from '../../../../validation/yup';
import { RevertBidButton } from '../../../common/RevertBidButton';
import { Form, Formik } from 'formik';
import { isEqual, keys } from 'lodash';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { CurrencyInputField } from '../../../forms/CurrencyInputField';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { apiUtils, isRequesting, moneyUtils, numericUtils } from '../../../../utils';
import { constants, errorMessages } from '../../../../constants';
import { Table } from '../../../bidding/common/table';
import { Checkbox } from '../../../controls';
import { SettlementAgentClientAgreement } from '../../../../types/bid-as-dealer/SettlementAgentClientAgreement';
import { TradingLimitDescription } from './TradingLimitDescription';

enum TradingLimitType {
    Common,
    ByRating,
}

interface FormParams {
    commonLimit: number;
    byRating: { [key in Rating]?: number | null };
    [TradingLimitType.Common]: boolean;
    [TradingLimitType.ByRating]: boolean;
}

interface Props {
    onCancel: () => void;
    onTrackChanges: (isChanged: boolean) => void;
    company: SettlementAgentClientAgreement;
}

interface TrackFormChangesProps {
    initialValues: FormParams;
    values: FormParams;
    onChanged: (isChanged: boolean) => void
}


const TrackFormChanges: React.FC<TrackFormChangesProps> = ({ initialValues, values, onChanged }) => {
    const isChanged = useRef(false);
    useEffect(() => {
        const isFormInitialValuesChanged = !isEqual(
            { byRating: values.byRating, commonLimit: values.commonLimit },
            { byRating: initialValues.byRating, commonLimit: initialValues.commonLimit }
        )
        if (isChanged.current !== isFormInitialValuesChanged) {
            onChanged(isFormInitialValuesChanged);
            isChanged.current = isFormInitialValuesChanged;
        }
        return () => {
            isChanged.current = false;
        } // eslint-disable-next-line
    }, [initialValues, values])
    return null;
}

export const TradingLimitPanelContent: React.FC<Props> = ({ onCancel, onTrackChanges, company }) => {
    const dispatch = useDispatch();
    const errorMessage = errorMessages.shouldBeBetween(0, moneyUtils.money(constants.dailyTradingLimit.max));

    const requestStatusUpdateRequest = useSelector((s: AppState) => s.clientsCompanies.requestStatusUpdateRequest);

    const isSaving = company == null || isRequesting(requestStatusUpdateRequest[company.signatory?.id]);

    const defaultValuesByRating = useMemo(() => {
        const result: { [ket in Rating]?: number } = {};
        Ratings.forEach((r) => {
            result[r] = company?.dailyTradingLimit?.byRating[r] ?? 0
        })
        return result
    }, [company])


    if (company == null) return null;

    const handleSave = (data: FormParams) => {
        const requestData: UpdateSettlementAgentAgreementStatus = {
            bidderId: company.signatory.id,
            agreementStatus: SettlementAgreementStatus.confirmed,
            dailyTradingLimit: {
                common: data[TradingLimitType.Common] ? Number(data.commonLimit) : undefined,
                byRating: data[TradingLimitType.ByRating]
                    ? data.byRating
                    : apiUtils.normalize(Ratings, r => r, () => null)
            },
        };
        dispatch(clientsCompaniesActions.updateAgreementRequest(requestData));
    };

    const isByRating = !keys(company.dailyTradingLimit.byRating).every(key => company.dailyTradingLimit.byRating[key as Rating] === null);

    const defaultFormValues = {
        commonLimit: company.dailyTradingLimit.common ?? constants.defaultDailyTradingLimit,
        byRating: { ...defaultValuesByRating },
        [TradingLimitType.Common]: company.dailyTradingLimit.common != null,
        [TradingLimitType.ByRating]: isByRating,
    };

    const getYupRatingsRules = () => {
        const result: { [ket in Rating]?: any } = {};
        Ratings.forEach((r) =>
            result[r] =
                yup.number().transform(v => numericUtils.isNumber(v) ? v : null)
                    .typeError(errorMessage).required(errorMessage)
                    .max(constants.dailyTradingLimit.max, errorMessage)
        )
        return result
    }

    const validationSchema = yup.object().shape({
        commonLimit: yup.number().transform(v => numericUtils.isNumber(v) ? v : null)
            .when(String(TradingLimitType.Common), {
                is: (tradingLimitType: TradingLimitType) => tradingLimitType,
                then: () => yup.number().typeError(errorMessage).required(errorMessage)
                    .max(constants.dailyTradingLimit.max, errorMessage),
                otherwise: () => yup.number().nullable()
            }),
        byRating: yup.object()
            .when(String(TradingLimitType.ByRating), {
                is: (tradingLimitType: TradingLimitType) => tradingLimitType,
                then: () => yup.object().shape({ ...getYupRatingsRules() }),
            }),
    });

    const renderRevertRatingButton = (currentValue: number | undefined, rating: Rating, setFieldValue: (key: string, value: number) => void) => {
        if (currentValue !== defaultValuesByRating[rating]) {
            return (
                <RevertBidButton
                    onRevert={() => setFieldValue(`byRating.${rating}`, defaultValuesByRating[rating] || 0)}
                />
            )
        }
        return null
    }

    return (
        <Formik
            initialValues={defaultFormValues}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={handleSave}
        >
            {({ values, setFieldValue, errors }) => {
                const getColumns = () => {
                    const columns: IColumnDefinition<{ rating: Rating, tradingLimit: number, disabled: boolean }>[] = [
                        {
                            columnKey: 'rating',
                            renderColumnHeaderContent: () => 'Rtg',
                            renderColumnContent: position => position.rating,
                            headerClassName: 'data-list-rating',
                            bodyClassName: 'data-list-rating',
                        }, {
                            columnKey: 'tradingLimit',
                            renderColumnHeaderContent: () => (
                                <div>Daily Trading Limit, USD<span className="text-red">*</span></div>
                            ),
                            renderColumnContent: position => (
                                <div className="flex-row justify-content-end">
                                    <CurrencyInputField
                                        className="limits-form-field form-control-sm text-right"
                                        placeholder="0"
                                        name={`byRating.${position.rating}`}
                                        label=""
                                        markRequired={false}
                                        loading={false}
                                        disabled={position.disabled}
                                        defaultValue={null}
                                        isValueChanged={position.tradingLimit !== defaultValuesByRating[position.rating]}
                                    />
                                    {renderRevertRatingButton(position.tradingLimit, position.rating, setFieldValue)}
                                </div>
                            ),
                            headerClassName: 'data-daily-trading-limit',
                            bodyClassName: 'data-daily-trading-limit',
                        }
                    ];
                    return columns.map(c => new ColumnBuilder(c));
                }

                return (
                    <Form className="limits-form">
                        <TrackFormChanges
                            initialValues={defaultFormValues}
                            values={values}
                            onChanged={(isChanged) => onTrackChanges(isChanged)}
                        />
                        <TradingLimitDescription
                            isCommon={values[TradingLimitType.Common]}
                            isByRating={values[TradingLimitType.ByRating]}
                        />
                        <div className="limits-form-header flex-row">
                            <span className="text-medium">Set daily trading limit</span>
                        </div>
                        <div className="limits-form-all">
                            <Checkbox
                                label="Total"
                                onChange={() => setFieldValue(String(TradingLimitType.Common), !values[TradingLimitType.Common])}
                                checked={values[TradingLimitType.Common]}
                            />
                            <CurrencyInputField
                                className="limits-form-field usd-label form-control-sm text-right"
                                placeholder="0"
                                name="commonLimit"
                                label=""
                                markRequired={false}
                                loading={false}
                                disabled={isSaving || !values[TradingLimitType.Common]}
                                defaultValue={null}
                            />
                        </div>
                        <div className="limits-form-by-rating">
                            <Checkbox
                                label="By rating"
                                onChange={() => setFieldValue(String(TradingLimitType.ByRating), !values[TradingLimitType.ByRating])}
                                checked={values[TradingLimitType.ByRating]}
                            />
                            <div className="limits-form-notify text-warm-grey">If a zero limit is set, then the
                                buyer will not be able to buy securities with the corresponding rating
                            </div>
                            <Table
                                columns={getColumns()}
                                dataItems={Ratings.map((r) => ({
                                    rating: r,
                                    tradingLimit: values.byRating[r],
                                    disabled: !values[TradingLimitType.ByRating]
                                }))}
                            />
                        </div>
                        <div className="limits-form-footer flex-row">
                            <button
                                className="btn btn-ghost btn-sm"
                                type="button"
                                onClick={onCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-main btn-sm"
                                type="submit"
                                disabled={!(values[TradingLimitType.ByRating] || values[TradingLimitType.Common]) ||isSaving || !!keys(errors).length}
                            >
                                Save
                            </button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
