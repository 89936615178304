import React from 'react';
import classNames from 'classnames';
import { bwicStatuses } from '../../constants/bwic.statuses';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { OnHoverTooltip } from './OnHoverTooltip';
import IconSVG from '../../styles/svg-icons';
import { LiveBiddingTooltipContent } from './LiveBiddingTooltipContent';

export interface BwicStatusLabelProps {
    status: BwicStatus;
    liveBidding?: boolean;
    isParsed?: boolean;
    directBidding?: boolean;
    bwicTypeIconVisible?: boolean;
    isAllToAll?: boolean;
    tooltip?: string;
    render?: React.ReactNode;
}

export function BwicStatusLabel({
    status,
    liveBidding = false,
    isParsed = false,
    directBidding = false,
    bwicTypeIconVisible = true,
    isAllToAll = false,
    tooltip,
    render }: BwicStatusLabelProps) {
    const bwicStatus = bwicStatuses.getByKey(status);

    if (bwicStatus == null) return null;

    const cssClass = classNames('label-status', {
        'label-status-scheduled': status === BwicStatus.scheduled,
        'label-status-bidding': status === BwicStatus.bidding,
        'label-status-finished': status === BwicStatus.finished,
        'label-status-canceled': status === BwicStatus.canceled
    });

    return (
        <div className="bwic-status flex-row flex-none">
            <OnHoverTooltip overlay={tooltip ?? bwicStatus.title}>
                {render ?? <div className={cssClass}>{bwicStatus.title}</div>}
            </OnHoverTooltip>
            {
                bwicTypeIconVisible &&
                <span className="bwic-status-label">
                    <BwicTypeLabel
                        isParsed={isParsed}
                        directBidding={directBidding}
                        isAllToAll={isAllToAll}
                        liveBidding={liveBidding}
                    />
                </span>
            }
        </div>
    );
}

interface BwicTypeProps {
    isParsed: boolean;
    directBidding: boolean;
    isAllToAll: boolean;
    liveBidding: boolean;
}

export function BwicTypeLabel({ isParsed, directBidding, isAllToAll, liveBidding }: BwicTypeProps) {
    if (isParsed) return <ParsedLabel />
    if (isAllToAll) return <AllToAllLabel />
    if (directBidding) return <KtxLabel />
    if (liveBidding) return <Live />

    return null;
}

function ParsedLabel() {
    return (
        <OnHoverTooltip overlay="Off-platform BWIC">
            <IconSVG name="off-platform" width={16} height={16} />
        </OnHoverTooltip>
    );
}

function KtxLabel() {
    return (
        <OnHoverTooltip overlay="DirectBidding BWIC">
            <span className="bwic-state-ktx">KTX</span>
        </OnHoverTooltip>
    )
}

function AllToAllLabel() {
    return (
        <OnHoverTooltip overlay="All-to-All BWIC">
            <span className="bwic-state-ktx">All-to-All</span>
        </OnHoverTooltip>
    );
}

function Live() {
    return (
        <OnHoverTooltip overlay={<LiveBiddingTooltipContent />}>
            <span className="bwic-state-live">LIVE</span>
        </OnHoverTooltip>
    );
}
